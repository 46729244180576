<template>
    <div id="page-component-debug">
        <psi-page-title
            title="Component Debug"
            icon="mdi-bug"
            :breadcrumbs="[]"
        ></psi-page-title>
        <v-card class="mt-4">
            <v-card-text>
                <h4 class="mb-4">Component Debug</h4>
                <v-btn @click="setError('Sample Error')">Set Error</v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "component-debug",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Uploads", ["setError"]),
    },
};
</script>

<style scoped>
</style>