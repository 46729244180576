var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "page-component-debug" } },
    [
      _c("psi-page-title", {
        attrs: { title: "Component Debug", icon: "mdi-bug", breadcrumbs: [] }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c(
            "v-card-text",
            [
              _c("h4", { staticClass: "mb-4" }, [_vm._v("Component Debug")]),
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.setError("Sample Error")
                    }
                  }
                },
                [_vm._v("Set Error")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }